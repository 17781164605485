<!--
 * 
 *邀请有奖二期 (瓜分十万现金奖励)
-->

<template>
  <div>
    <div id="invite-activity">
      <!-- 固定顶部 -->
      <div class="fixedBox" :class="!isApp ? 'appFixedTop' : ''">
        <!-- tabs -->
        <div class="tabs">
          <div
            v-for="item in tabs"
            :key="item.value"
            :class="item.value == activeTab ? 'activeTab' : ''"
            @click="inviteTabs(item.value)"
          >
            {{ item.name }}

            <div v-if="item.value == activeTab" class="tabLine"></div>
          </div>
        </div>
        <!-- 我的排名 -->
        <div v-if="activeTab == 2" class="myRankBox">
          <div class="rankItem myRank">
            <div class="userInfo">
              <div class="number myNumber">{{ isLogin ? myTopNum(myTop.user_id) : ' - ' }}</div>
              <div class="name">
                <div>我</div>
              </div>
            </div>
            <div>
              <van-icon name="warning-o" @click="popShow = true" />
              邀请<span class="inviteNum"> &nbsp;{{ myTop.invite_total }} &nbsp;</span>人
            </div>
          </div>
        </div>
      </div>

      <!-- 活动信息 -->
      <div v-if="activeTab == 1" class="activityBox" :class="!isApp ? 'appTop' : ''">
        <img
          src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1655444900014.jpg"
        />
        <div class="allActivity" :class="!isApp ? 'allActivityTop' : ''" @click="toHistoryActivity">
          历史活动
        </div>
        <div
          v-if="activityStatus == 3"
          class="allActivity winnerBtn"
          :class="!isApp ? 'winnerBtnTop' : ''"
          @click="toAwardList"
        >
          获奖名单
        </div>
      </div>

      <!-- 邀请榜单 -->
      <div v-if="activeTab == 2" class="rankBox" :class="!isApp ? 'appRankTop' : ''">
        <div class="rankList">
          <div class="rankItem" v-for="(item, index) in topList" :key="index">
            <div class="userInfo">
              <div class="number" :style="index < 3 ? 'background: #3CDBE8;color:black;' : ''">
                {{ index + 1 }}
              </div>
              <!-- <div class="headImg">
								<img src="../imgs/comm/pic_avatar_login.png" alt="" srcset="">
							</div> -->
              <div class="name">
                <div :style="index < 3 ? 'color:#3CDBE8' : ''">{{ item.user_name }}</div>
              </div>
            </div>

            <div>
              邀请<span class="inviteNum">{{ item.invite_total }}</span
              >人
            </div>
          </div>

          <div class="rankTip">榜单仅显示前100名</div>
        </div>
      </div>

      <div v-if="activeTab == 3">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="list.length ? '-到底啦-' : ''"
          offset="200"
          class="recordList"
          :class="!isApp ? 'appTop' : ''"
          @load="onLoad"
        >
          <div class="recordItem" v-for="item in list" :key="item.id">
            <div class="name">
              <div>{{ item.user_name }}</div>
              <!-- <div>ID：{{ item.receive_user_id }}</div> -->
            </div>

            <div class="success">
              <div v-if="item.id_verify === 1 && item.is_presale == 1">邀请成功</div>
              <div v-if="item.id_verify === 1 && item.is_presale == 0">已实名，未预付款</div>
              <div v-if="item.id_verify === 0">已注册，未实名</div>

              <div v-if="item.id_verify === 1 && item.is_presale == 1">
                {{ getTime(item.presale_time) }}
              </div>
              <div v-if="item.id_verify === 1 && item.is_presale == 0">
                {{ getTime(item.verify_time) }}
              </div>
              <div v-if="item.id_verify === 0">{{ getTime(item.created_time) }}</div>
              <div></div>
            </div>
          </div>
        </van-list>

        <div v-if="!list.length" class="dataNullBox">
          <div>
            <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
          </div>
          <div class="text">暂无邀请记录</div>
        </div>
      </div>

      <!-- 底部遮罩 -->
      <div class="footer">
        <!-- 这三个按钮在活动进行中显示 -->
        <div class="shareBtnBox">
          <!-- <shareBtn class="shareBtn" :activityId="activityId" :posterBg="posterBg" /> -->
          <div class="posterBtn" @click="qrcodeDom">生成专属邀请海报</div>
          <div class="shareBtn" @click="openCopytextPop">生成专属邀请文本</div>
          <div class="shareBtn doubleText" @click="copyCode">
            <div>复制邀请码</div>
            <div class="myCode">我的邀请码：{{ isLogin ? myInviteCode : '--' }}</div>
          </div>
        </div>

        <!-- 海报 -->
        <div style="position: fixed; top: -200%; font-size: 0">
          <div id="testPost">
            <img class="posterBg" :src="posterBg" />
            <!-- <div id="qrcode" class="qrcodeImg"></div> -->
            <img class="qrcodeImg" :src="qrcodeImg" alt="" srcset="" />
          </div>
        </div>

        <van-popup class="vantPop" v-model:show="posterPop">
          <div class="poster">
            <!-- <van-image width="100" height="100" :src="posterImg" /> -->
            <img style="pointer-events: auto !important" :src="posterImg" alt="" srcset="" />

            <div v-if="posterImg" class="saveTip">长按图片保存</div>
          </div>
        </van-popup>

        <PageLoading :show="pageLoading" />

        <div class="otherStatusBtn">
          <!-- <div class="getBtn" @click="openGetMoneyPop">立即领取 {{ carveupPrice / 100 }} 元现金</div> -->
          <!-- <div>本期活动已结束</div> -->
          <div v-if="carveupStatus == 1">瓜分10万奖金，待领取</div>
          <div v-if="carveupStatus == 2" class="getBtn" @click="openGetMoneyPop">
            立即领取 {{ carveupPrice / 100 }} 元现金
          </div>
          <div v-if="carveupStatus == 3">你不符合领取奖金资格</div>
          <div v-if="carveupStatus == 4">瓜分10万奖金已过期</div>
          <div v-if="carveupStatus == 5">
            {{ withdrawType == 1 ? '瓜分10万奖金已领取' : '瓜分10万奖金已提交领取' }}
          </div>
          <div v-if="carveupStatus == 6">领取失败，请联系客服</div>

          <div v-if="carveupStatus == 8">
            领取失败，
            <van-count-down
              style="display: inline-block"
              millisecond
              format="mm:ss"
              :time="count_down * 1000"
              @change="e => changeTime(e, item, index)"
              @finish="finistTime"
            />后可再次领取
          </div>
          <div class="getFail" v-if="carveupStatus == 9">
            <div>领取失败，请联系客服：</div>
            <div>电话({{ dh }})、QQ({{ qq }})、微信({{ wx }})</div>
          </div>
        </div>

        <div class="getMoneyBox"></div>
        <!-- <div class="toRecord" @click="toInviteRecord">邀请记录<van-icon name="arrow" /></div> -->
      </div>

      <!-- 说明弹窗 -->
      <van-popup class="vantPop" v-model:show="popShow">
        <div class="popup">
          <div class="content">
            <div>邀请人数相同，则按完成时间先后进行排名。</div>
          </div>
          <div class="btn">
            <div @click="popShow = false">知道了</div>
          </div>
        </div>
      </van-popup>

      <!-- 支付宝领取弹窗 -->
      <van-popup class="vantPop" v-model:show="getMoneyPop">
        <div class="popup getMoneyPopStyle">
          <div class="title">
            恭喜瓜分获得<span style="color: #3cdbe8"> {{ carveupPrice / 100 }} </span>元
          </div>
          <div class="tip">填写支付宝账号后，3个工作日内到账</div>
          <div class="inputBox">
            <van-field
              class="input"
              center
              :border="false"
              disabled
              :label-width="labelWidth"
              v-model="dest_name"
              label="姓名"
            />
          </div>
          <div class="inputBox">
            <van-field
              class="input"
              type="digit"
              :label-width="labelWidth"
              :border="false"
              center
              v-model="dest_account"
              maxlength="11"
              label="账号"
              placeholder="请输入支付宝绑定的手机号"
            />
          </div>

          <div
            style="
              padding: 0 20px 15px 20px;
              text-align: left;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.7);
            "
          >
            <div>请确认：</div>
            <div>所填手机号已开通支付宝，并与看漫数字藏品的实名认证一致！</div>
          </div>

          <div
            class="submitGet"
            :class="dest_account.length !== 11 ? 'disSubmitGet' : ''"
            @click="submitGet"
          >
            确定并领取
          </div>
        </div>
      </van-popup>

      <!-- 银行领取弹窗 -->
      <van-popup class="vantPop" v-model:show="getMoneyBankPop">
        <div class="popup getMoneyPopStyle">
          <div class="title">
            恭喜瓜分获得<span style="color: #3cdbe8"> {{ carveupPrice / 100 }} </span>元
          </div>
          <div class="tip">提交信息后，3-5个工作日内到账</div>
          <div class="inputBox">
            <van-field
              class="input"
              center
              :border="false"
              disabled
              :label-width="labelWidth"
              v-model="bankBankForm.dest_name"
              label="姓名"
            />
          </div>
          <div class="inputBox">
            <van-field
              class="input"
              type="digit"
              :label-width="labelBankWidth"
              :border="false"
              center
              disabled
              v-model="bankBankForm.myMobile"
              maxlength="11"
              label="手机号"
            />
          </div>

          <div
            style="
              padding: 0 20px 0 20px;
              text-align: left;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.7);
            "
          >
            领取仅限使用本人银行卡
          </div>

          <div class="inputBox">
            <van-field
              class="input"
              center
              type="digit"
              :border="false"
              :label-width="labelBankWidth"
              v-model="bankBankForm.dest_account"
              label="银行卡号"
              placeholder="请填写银行卡号"
            />
          </div>
          <div class="inputBox">
            <van-field
              class="input"
              center
              type="digit"
              :border="false"
              :label-width="labelBankWidth"
              v-model="bankBankForm.reserved_mobile"
              label="预留手机号"
              placeholder="请填写银行预留手机号"
            />
          </div>
          <div class="inputBox">
            <van-field
              class="input"
              center
              :border="false"
              :label-width="labelBankWidth"
              v-model="bankBankForm.account_addr"
              label="开户地"
              placeholder="请填写开户地"
            />
          </div>
          <div class="inputBox">
            <van-field
              class="input"
              center
              :border="false"
              :label-width="labelBankWidth"
              v-model="bankBankForm.withdraw_bank"
              label="开户银行"
              placeholder="请填写开户银行"
            />
          </div>
          <div class="inputBox">
            <van-field
              class="input"
              center
              :border="false"
              :label-width="labelBankWidth"
              v-model="bankBankForm.withdraw_sub_bank"
              label="开户支行"
              placeholder="请填写开户支行"
            />
          </div>

          <div
            class="submitGet"
            :class="dest_account.length !== 11 ? 'disSubmitGet' : ''"
            @click="submitGetBank"
          >
            确定并领取
          </div>
        </div>
      </van-popup>

      <!-- 复制文本弹窗 -->
      <van-popup class="vantPop" v-model:show="copytextPop">
        <div class="popup">
          <div class="copytextPop">
            <div class="title">文本已复制</div>
            <div class="tip">打开第三方软件粘贴给好友</div>
            <div class="copyContent">
              <div>{{ shareText }}</div>
              <!-- <div>https://collect.mh51.com/xxxxxxx</div> -->
              <div style="color: #3cdbe8; font-weight: 500" @click="copyText">复制文本</div>
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 说明弹窗 -->
      <van-popup class="vantPop" v-model:show="failPop">
        <div class="failPopup">
          <div class="content">
            <div class="title">{{ failTitle }}</div>
            <div>
              {{ failContent }}
            </div>
          </div>
          <div class="btn">
            <div @click.stop="closeFailPop">知道了</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  getTop,
  getuserinvitationlog,
  getactivityinfo,
  withdraw,
  withdrawways
} from '@/service/invite';
import { getUserCollection } from '@/service/user';
import { nftUtils } from '@/utils';
import moment from 'moment';
import shareBtn from '@/components/inviteSharePoster/inviteSharePoster';
import useClipboard from 'vue-clipboard3';

import domtoimage from 'dom-to-image';
import QRCode from 'qrcode';
import upload from '@/utils/upload.js';
import PageLoading from '@/components/loading/PageLoading.vue';
export default {
  name: 'invite',
  components: {
    shareBtn,
    PageLoading
  },
  setup() {
    const toastFn = inject('$toast');
    const { toClipboard } = useClipboard();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      pageLoading: false,
      posterImg: '', // 海报图片
      isFlag: true, // 用于处理ios需要点击两次的问题 （后期改成http链接可能不需要）
      qrcodeImg: null, // 二维码图片
      posterPop: false, // 弹窗显示
      activityId: 2,

      tabs: [
        {
          value: 1,
          name: '活动信息'
        },
        {
          value: 2,
          name: '邀请榜单'
        },
        {
          value: 3,
          name: '邀请记录'
        }
      ],
      activeTab: 1,
      popShow: false,
      topList: [],
      myTop: null,
      myTopItem: null,
      startTime: '',
      getMoneyPop: false,
      labelWidth: 40,
      copytextPop: false,
      posterBg: require('../imgs/poster/inviteVersion2.jpg'),
      myInviteCode: null,
      list: [],
      finished: false,
      page: 1,
      loading: true,
      dest_account: '',
      dest_name: '',
      activityStatus: null,
      shareText: '',
      idName: null,
      carveupStatus: null,
      carveupPrice: null,
      isApp: nftUtils.isInApp(),
      isLogin: nftUtils.isLogin(),
      getMoneyBankPop: false,
      bankBankForm: {
        myMobile: '',
        dest_account: '',
        dest_name: '',
        withdraw_bank: '', //开户银行
        withdraw_sub_bank: '', //开户支行
        account_addr: '', //开户地
        reserved_mobile: '' //预留手机号
      },
      labelBankWidth: 75,
      withdrawType: 1,
      failPop: false,
      failTitle: '领取失败',
      failContent: '领取失败',
      dh: 4006161961,
      qq: 4006161961,
      wx: 'kanman2022',
      count_down: null
    });

    const toInviteRecord = () => {
      router.push({ path: '/inviteRecord', query: { startTime: state.startTime } });
      nftUtils.setcnzzRecordByBtn('邀请记录');
    };

    // 去榜单里面找出我的排名，如果不再榜单内则显示 -
    const myTopNum = id => {
      let myTopNum = '-';
      for (let i in state.topList) {
        if (state.topList[i].user_id === id) {
          myTopNum = ++i;
        }
      }
      return myTopNum;
    };

    const inviteTabs = value => {
      state.activeTab = value;
      if (value == 1) {
        nftUtils.setcnzzRecordByBtn('活动信息');
      } else if (value == 2) {
        nftUtils.setcnzzRecordByBtn('邀请榜单');
      } else if (value == 3) {
        nftUtils.setcnzzRecordByBtn('邀请记录');
      }
    };

    // 获取榜单列表
    const getTopFn = () => {
      getTop({
        size: 100,
        act_id: state.activityId
      }).then(res => {
        console.log(res);
        if (res.status === 0) {
          state.topList = res.data.rank;
          state.myTop = res.data.user;
        } else {
        }
      });
    };

    // 复制邀请链接
    const copyText = async () => {
      nftUtils.setcnzzRecordByBtn('复制文本');
      try {
        await toClipboard(state.shareText);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    const openCopytextPop = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      const shareUrl =
        window.location.origin +
        '/login?invite_user_id=' +
        state.myInviteCode +
        '&activityId=' +
        state.activityId;
      state.shareText = '注册实名，最高抽取6666元奖金，还可领取免费空投。用浏览器打开：' + shareUrl;
      console.log(nftUtils.isSupprotNftShare());
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftShareText(state.shareText);
      } else {
        state.copytextPop = true;
        try {
          await toClipboard(state.shareText);
        } catch (e) {
          console.log(e);
        }
      }
    };

    // 复制邀请码
    const copyCode = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      nftUtils.setcnzzRecordByBtn('复制邀请码');
      try {
        await toClipboard(state.myInviteCode);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    // 跳转历史活动页面
    const toHistoryActivity = () => {
      nftUtils.setcnzzRecordByBtn('跳转历史活动页面');
      router.push({
        path: '/historyActivity'
      });
    };

    // 获取邀请记录
    const getInviteLog = () => {
      if (!state.isLogin) {
        state.loading = false;
        return;
      }
      getuserinvitationlog({
        page: state.page,
        page_size: 10,
        // act_id: 1
        act_id: state.activityId
      }).then(res => {
        console.log(res);
        if (res.status === 0) {
          state.pageLoading = false;
          state.list = state.list.concat(res.data || []);
          state.loading = false;
          if (!res.data || !res.data.length) {
            state.finished = true;
          }
        }
      });
    };

    // 加载更多
    const onLoad = () => {
      if (!state.finished && state.list.length) {
        console.log(state.page);
        state.page = state.page + 1;
      }
      getInviteLog();
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    // 获取活动信息（用于判断按钮状态）
    const getInfo = () => {
      getactivityinfo({
        activity_id: state.activityId
      }).then(res => {
        console.log('活动信息', res);
        state.activityStatus = res.data.status;
        state.carveupStatus = res.data.carveup_status;
        // state.carveupStatus = 8;
        state.carveupPrice = res.data.carveup_price;

        state.count_down = res.data.count_down;
      });
    };

    // 打开领取现金弹窗
    const openGetMoneyPop = async () => {
      // nftUtils.setcnzzRecordByBtn('打开领取现金弹窗');
      const { data, status } = await getUserCollection();
      if (data && status === 0) {
        console.log(data);
        if (!data.id_name) {
          router.push({ path: '/authentication' });
        } else {
          state.dest_name = formatName(data.id_name);
          state.dest_account = data.mobile;
          state.idName = data.id_name;
          state.bankBankForm = {
            myMobile: data.mobile,
            dest_account: '',
            dest_name: formatName(data.id_name),
            withdraw_bank: '', //开户银行
            withdraw_sub_bank: '', //开户支行
            account_addr: '', //开户地
            reserved_mobile: '' //预留手机号
          };

          withdrawways({}).then(res => {
            console.log(res);
            state.withdrawType = res.data.withdraw_way;
            if (res.data.withdraw_way == 1) {
              state.getMoneyPop = true;
              // state.getMoneyBankPop = true;
            } else {
              state.getMoneyBankPop = true;
            }
          });
        }
      }
    };

    // 确认领取现金
    const submitGet = () => {
      if (state.dest_account.length == 11) {
        withdraw({
          activity_id: state.activityId,
          withdraw_way: 1,
          dest_account: state.dest_account,
          dest_name: state.idName
        }).then(res => {
          state.getMoneyPop = false;
          getInfo();
          if (res.status === 0) {
            toastFn({ message: '领取成功' });
            nftUtils.setcnzzRecordByBtn('成功领取现金');
          } else {
            // toastFn({ message: res.message, duration: 3000 });
            if (res.status === 601) {
              state.failTitle = '领取失败,请联系客服';
              state.failContent = `联系方式：电话(${state.dh})、QQ(${state.qq})、微信(${state.wx})`;
              state.failPop = true;
            } else {
              state.failTitle = '领取失败,1小时后可再次领取';
              state.failContent = '请确认所填手机号已开通支付宝，并与看漫数字藏品的实名认证一致';
              state.failPop = true;
            }
          }
        });
      }
    };

    const closeFailPop = () => {
      state.failPop = false;
      getInfo();
    };

    const submitGetBank = () => {
      if (
        state.dest_account.length == 11 &&
        state.bankBankForm.dest_account &&
        state.bankBankForm.withdraw_bank &&
        state.bankBankForm.account_addr &&
        state.bankBankForm.reserved_mobile
      ) {
        withdraw({
          activity_id: state.activityId,
          withdraw_way: 2,
          dest_name: state.idName,

          dest_account: state.bankBankForm.dest_account,
          withdraw_bank: state.bankBankForm.withdraw_bank, //开户银行
          withdraw_sub_bank: state.bankBankForm.withdraw_sub_bank, //开户支行
          account_addr: state.bankBankForm.account_addr, //开户地
          reserved_mobile: state.bankBankForm.reserved_mobile //预留手机号
        }).then(res => {
          state.getMoneyBankPop = false;
          getInfo();
          if (res.status === 0) {
            toastFn({ message: '已提交领取' });
            nftUtils.setcnzzRecordByBtn('成功领取现金');
          } else {
            toastFn({ message: res.message, duration: 3000 });
          }
        });
      } else {
        toastFn({ message: '请填写所有信息', duration: 3000 });
      }
    };

    // 名字第一个字替换成*
    function formatName(str) {
      return new Array(str.length - 1).join('*') + str.substr(-2);
    }

    // 生成二维码
    const qrcodeDom = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      console.log('process.env.NODE_ENV', process.env.NODE_ENV);
      state.pageLoading = true;
      nftUtils.setcnzzRecordByBtn('生成专属邀请海报');
      let url = window.location;
      if (!window.location.origin) {
        url =
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '');
      } else {
        url = window.location.origin;
      }

      if (url.indexOf('mh51') > -1) {
        url = 'https://collect.yx03.com';
        // url = url.replace('https://collect.mh51.com/', 'https://collect.yx03.com/');
      }

      if (store.state.posterUrl) {
        // 上传过后不用生成和上传了
        havePosterUrlToPoster();
      } else {
        // 没有上传时重新上传
        await store.dispatch('refreshLoginInfo');
        QRCode.toDataURL(
          url +
            '/login?invite_user_id=' +
            (state.activityId == 1
              ? store.state.loginInfo.user_id
              : store.state.loginInfo.my_code) +
            '&activityId=' +
            state.activityId,
          { margin: 0 },
          (err, url) => {
            if (err) throw err;
            state.qrcodeImg = url;

            uploadDomToPoster(nftUtils.isIOS());
          }
        );
      }
    };
    // 直接转为Blob生成并上传的oss，iOS特殊处理，需要执行两次toBlob
    const uploadDomToPoster = isIos => {
      if (!store.state.posterUrl) {
        if (isIos) {
          domtoimage.toBlob(document.getElementById('testPost'), { quality: 1 }).then(dataUrl => {
            updateDomToPosterOnece();
          });
        } else {
          updateDomToPosterOnece();
        }
      } else {
        havePosterUrlToPoster();
      }
    };

    const havePosterUrlToPoster = () => {
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftSharePic(store.state.posterUrl);
        state.pageLoading = false;
      } else {
        state.posterImg = store.state.posterUrl;
        state.posterPop = true;
        state.pageLoading = false;
      }
    };

    // 直接转为toBlob，首次进入时两次生成并上传oss显示，第二次及以后，只需一次生成并上传oss显示
    const updateDomToPosterOnece = () => {
      domtoimage.toBlob(document.getElementById('testPost'), { quality: 1 }).then(dataUrl => {
        if (nftUtils.isSupprotNftShare()) {
          upload.uploadHandle64(dataUrl, function (link) {
            nftUtils.nftSharePic(link);
            state.pageLoading = false;
            store.state.posterUrl = link;
          });
        } else {
          upload.uploadHandle64(dataUrl, function (link) {
            state.posterImg = link;
            state.posterPop = true;
            state.pageLoading = false;
            store.state.posterUrl = link;
          });
        }
      });
    };

    // 计算与当前时间时间差(没用到)
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const toAwardList = () => {
      router.push({
        path: '/awardList',
        query: { activityId: state.activityId }
      });
      nftUtils.setcnzzRecordByBtn('获奖名单');
    };

    const changeTime = (val, item, index) => {
      // getInfo();
    };

    const finistTime = () => {
      getInfo();
    };

    onMounted(async () => {
      if (state.isLogin) {
        await store.dispatch('refreshLoginInfo');
        console.log(store);
        if (store.state.loginInfo) {
          state.myInviteCode = store.state.loginInfo.my_code;
        }
      }

      getTopFn();
      getInfo();
      nftUtils.setcnzzRecordByBtn('进入邀请有奖页面');
    });

    return {
      ...toRefs(state),
      toInviteRecord,
      myTopNum,
      inviteTabs,
      copyText,
      getTopFn,
      copyCode,
      toHistoryActivity,
      onLoad,
      getTime,
      openGetMoneyPop,
      submitGet,
      openCopytextPop,
      qrcodeDom,
      diffTime,
      toAwardList,
      submitGetBank,
      closeFailPop,
      changeTime,
      finistTime
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
#invite-activity {
  background: black;
  .fixedBox {
    position: fixed;
    background: black;
    width: 100%;
    top: 0;
    left: 0;
  }

  .appFixedTop {
    padding-top: 70px;
  }

  .tabs {
    display: flex;
    justify-content: center;
    line-height: 40px;
    padding: 7px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);

    .activeTab {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }

    div {
      padding: 0 18px;
      position: relative;

      .tabLine {
        height: 3px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
      }
    }
  }

  .activityBox {
    // position: relative;
    padding: 64px 0 110px 0;
    text-align: center;
    color: #ffffff;
    background: black;

    img {
      width: 100%;
    }

    .allActivity {
      position: fixed;
      right: 0;
      top: 100px;
      width: 71px;
      height: 28px;
      background: #58dedd;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      border-radius: 14px 0 0 14px;
    }

    .allActivityTop {
      top: 170px;
    }

    .winnerBtn {
      top: 140px;
    }

    .winnerBtnTop {
      top: 210px;
    }
  }

  .rankBox {
    padding: 102px 20px 0 20px;
    background: #000000;
  }

  .rankList {
    padding-bottom: 110px;

    .rankTip {
      text-align: center;
      padding: 25px 0 0 0;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .rankItem {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;

    .userInfo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .number {
      color: rgba(255, 255, 255, 1);
      width: 28px;
      height: 28px;
      background: rgb(54, 51, 64);
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      line-height: 28px;
      border-radius: 50%;
    }

    .myNumber {
      background: #fff;
      color: black;
    }

    .headImg {
      margin-left: 20px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #3cdbe8;
        object-fit: cover;
      }
    }

    .name {
      margin-left: 16px;

      :first-child {
        font-size: 14px;
        font-weight: 600;
      }

      :last-child {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .inviteNum {
      color: #3cdbe8;
    }
  }

  // .myRankBox {
  //   position: fixed;
  //   width: 100%;
  //   top: 55px;
  // }

  .myRank {
    background: #2f293f;
    padding: 10px 20px;
    margin-top: 0;
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 110px;
    background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);

    .shareBtnBox {
      position: absolute;
      bottom: 24px;
      width: 335px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
    }

    .otherStatusBtn {
      // width: 100%;
      // display: flex;
      // justify-content: space-around;
      position: absolute;
      bottom: 72px;
      left: 50%;
      transform: translateX(-50%);

      > div {
        width: 335px;
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: #1c172a;
        background: linear-gradient(132deg, #5b8d80 0%, #248795 100%);
        // background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        border-radius: 4px 4px 4px 4px;
      }

      .getBtn {
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      }

      .getFail {
        > div {
          line-height: 18px;
          white-space: nowrap;
        }
        :last-child {
          transform: scale(0.9);
        }
      }
    }

    .doubleText {
      line-height: 13px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .myCode {
      font-size: 8px;
      font-weight: 100;
      color: #1c172a;
    }

    .getMoneyBox {
      position: absolute;
      bottom: 24px;
      width: 100%;
      left: 0;
    }
  }

  .popup {
    width: 300px;
    background: #2f293f;
    border-radius: 8px 8px 8px 8px;
    text-align: left;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;

    .content {
      padding: 30px 16px;
      line-height: 26px;
    }

    .btn {
      div {
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        border-top: 1px solid #494359;
      }
    }
  }

  .getMoneyPopStyle {
    text-align: center;
    padding: 32px 0 24px 0;
    .input {
      background: #1c172a;
      border-radius: 8px;
      color: white;
      margin: 20px 0;
      font-size: 14px;
      font-weight: 400;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }

    .tip {
      margin-top: 5px;
      color: rgba(255, 255, 255, 0.7);
    }

    .inputBox {
      padding: 0 20px;
    }

    .submitGet {
      width: 116px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin: 0 auto;
      color: #1c172a;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 8px 8px 8px 8px;
    }

    .disSubmitGet {
      background: linear-gradient(132deg, #5b8d80 0%, #248795 100%);
    }
  }

  .copytextPop {
    padding: 24px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;

    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .tip {
      margin-top: 2px;
      color: rgba(255, 255, 255, 0.7);
    }

    .copyContent {
      margin-top: 16px;
      background: #1c172a;
      border-radius: 4px 4px 4px 4px;
      padding: 12px;

      line-height: 22px;
    }
  }

  .recordList {
    padding: 0 20px;
    padding-top: 54px;
    .recordItem {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      margin-top: 20px;

      .name {
        :first-child {
          font-size: 14px;
        }

        :last-child {
          margin-top: 6px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .success {
        text-align: right;

        :first-child {
          font-weight: 600;
        }

        :last-child {
          margin-top: 6px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  .dataNullBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 150px;
      height: 110px;
    }

    .text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .appTop {
    padding-top: 134px;
  }

  .appRankTop {
    padding-top: 172px;
  }
}

.disPosterBtn {
  opacity: 0.6;
  font-size: 16px;
}
#testPost {
  position: relative;
  display: inline-block;
  .posterBg {
    width: 616px;
    height: 1044px;
  }

  .qrcodeImg {
    width: 228px;
    height: 228px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // top: 352px;
    border: 6px solid;
    border-radius: 2px 2px 2px 2px;
    border-image: linear-gradient(180deg, rgba(255, 233, 203, 1), rgba(141, 129, 118, 1)) 3 3;
  }
}

.poster {
  width: 308px;

  .saveTip {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }

  img {
    width: 100%;
  }
}

.failPopup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
</style>
<style lang="scss">
#invite-activity {
  .vantPop {
    background: transparent !important;
  }

  .van-field__label,
  .van-field__value,
  .van-field__control {
    color: white;
  }

  .shareBtn,
  .posterBtn {
    text-align: center;
    width: 105px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 36px;
    color: #1c172a;
  }

  .qrcodeImg {
    top: 292px;
  }
}
</style>
